import React from 'react';

const lists = [
  'वीर पुर्खा (कविता)',
  'गाउँको माया (सामाजिक कथा)',
  'संस्कृतिको नयाँ यात्रा (आत्मपरक निबन्ध)',
  'योगमाया (राष्ट्रिय जीवनी)',
  'साथीलाई चिठी (चिठी)',
  'त्यो फेरि फर्कला ? (मनोवैज्ञानिक कथा)',
  'पर्यापर्यटनका सम्भावना र आयाम (वस्तुपरक निबन्ध)',
  'लौ आयो ताजा खबर (लघु नाटक)',
  'सफलताको कथा (रिपोर्ताजमूलक रचना)',
  'कृषिशालामा एक दिन (संवाद)',
  'रारा भ्रमण (दैनिकी)',
  'जलस्रोत र ऊर्जा (वक्तृता)',
];

const Nepali11 = () => {
  return lists.map((el, index) => (
    <li
      className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
      key={el}
    >
      {el}
    </li>
  ));
};

export default Nepali11;
