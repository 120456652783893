import React from 'react';

const lists = [
  'Introduction to Business Finance',
  'Financial Statements and Reporting',
  'Financial Analysis',
  'Source of Fund',
  'Capital Structure',
  'Bond and Stock Valuation',
  'Capital Investment Decision',
  'Working Capital and Current Assets Management',
  'Dividends and Dividend Payment Procedure',
  'International Finance',
];

const Finance12 = () => {
  return lists.map((el, index) => (
    <li
      className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
      key={el}
    >
      {el}
    </li>
  ));
};

export default Finance12;
