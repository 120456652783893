import React from 'react';
import Logo from '../assets/images/padandaslogo.png';
import ServicesImage from '../assets/images/services.jpg';
import BookIcon from '../assets/images/book.png';
import BookIcon1 from '../assets/images/book2.png';
import Carrer from '../assets/images/carrer.png';
import Teacher from '../assets/images/teacher.png';
import Syllabus from '../assets/images/syllabus.png';
import News from '../assets/images/news.png';

import Grid from '../utils/Grid';

const Contents = ({ head, image, description }) => (
  <div className="bg-white transition-all duration-100 hover:bg-subwhite text-gray leading-7   cursor-pointer shadow-2xl p-5 flex flex-col items-center">
    <img alt="icon" src={image} className="w-24 h-24 object-fit" />
    <h1 className="text-black">{head}</h1>
    <p className="  text-center">{description}</p>
  </div>
);

const ContentSection = () => {
  return (
    <div className=" flex-1 p-5 mt-10">
      <div className="  flex flex-wrap justify-around items-center">
        <div className="lg:w-96 sm:w-full text-center">
          <h1 className="text-4xl font-bold text-primary">Padandaas</h1>
          <h1 className="text-2xl font-bold text-primary">
            Education For Everyone
          </h1>
          <p className="text-xl mt-2">
            We are E-learning platform that provides all the learning materials
            (Notes, OLD Question collection, Solutions, MCQs, Syllabus & Mark
            distribution) of all subjects free of cost for everyone.
          </p>
          <button className="bg-primary text-white px-2 py-1 mt-3 rounded hover:bg-white hover:text-primary">
            Explore
          </button>
        </div>
        <div className="w-96 md:w-80 ">
          <img alt="Padandaas Logo" src={Logo} />
        </div>
      </div>
      <div className=" mt-10 flex flex-wrap justify-around items-center">
        <div className="w-96 md:w-80">
          <img alt="Padandaas Logo" src={ServicesImage} />
        </div>
        <div className="w-96 text-center">
          <h1 className="text-4xl font-bold text-primary">Our Services</h1>

          <p className="text-xl mt-2">
            Padandaas is a visionary project with the ambitions of creating new
            wave in Nepalese Education System. We are taking a step in a time,
            currently we are focusing on SEE and +2 level. After fully cementing
            those level, we are looking forward to engineering , BBA ,BCA, BSC
            CSIT and all other courses of all the universities that are
            currently been taught in Nepal.
          </p>
        </div>
      </div>
      <div className="mt-10">
        <Grid>
          <Contents
            image={BookIcon}
            head="Notes"
            description="Chapterwise Handwritten PDF notes,MCQS of all subjects"
          />
          <Contents
            image={BookIcon1}
            head="Old Questions"
            description="Chapterwise and Yearwise old question collection and their
            solutions"
          />
          <Contents
            image={Carrer}
            head="Career Counselling"
            description="Entrance Preparation, skills Training, Colleges and career recommendation"
          />
          <Contents
            image={Teacher}
            head="Interaction with Teachers"
            description="Student and teacher forum for online discussion"
          />
          <Contents
            image={Syllabus}
            head="Syllabus"
            description="Complete Syllabus guide with chapters "
          />
          <Contents
            image={News}
            head="News "
            description="Latest news and blogs"
          />
        </Grid>
      </div>
    </div>
  );
};

export default ContentSection;
