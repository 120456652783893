import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DropDownContents = {
  NEB: ['SEE', 11, 12],
  TU: ['IOST', 'IOE', 'IOM'],
  IOST: ['CSIT', 'BIT'],
  IOE: ['BCT', 'BCE', 'BEX'],
};

const SignUp = () => {
  const [stage, setStage] = useState(1);
  const [board, setBoard] = useState('');
  const navigate = useNavigate();
  const [course, setCourse] = useState('');
  const [level, setLevel] = useState('');
  return stage === 1 ? (
    <div className="flex   justify-center  min-h-screen w-full">
      <div className="mt-10 w-full md:w-96  text-center ">
        <h1 className="text-4xl">Sign Up</h1>
        <div className=" border py-7 px-4 mt-6">
          <h1 className="text-2xl">General</h1>
          <div className="flex gap-4 items-center mt-5 ">
            <label className="w-16">Name:</label>
            <input
              placeholder="Eg: John Doe"
              className="border px-3 py-1 w-full rounded"
            />
          </div>
          <div className="flex gap-4 items-center mt-5 ">
            <label className="w-16">Email:</label>
            <input
              placeholder="Eg: johndoe@gmail.com"
              className=" border px-3 py-1 w-full rounded"
            />
          </div>
          <div className="flex gap-4 items-center mt-5 ">
            <label className="w-16">Phone:</label>
            <input
              placeholder="Eg: 98111111111"
              className=" border px-3 py-1 w-full rounded"
            />
          </div>
          <div className="flex gap-4 items-center mt-5 ">
            <label className="w-14">Birthday:</label>
            <input
              type="date"
              placeholder="Eg: 98111111111"
              className=" border px-3 py-1 w-full rounded"
            />
          </div>
          <div className="flex gap-4 items-center mt-5 ">
            <label className="w-14">Gender:</label>
            <select className=" border px-3 py-1 w-full rounded">
              <option value="">Choose your gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="neutral">Prefer not to say</option>
            </select>
          </div>
          <div className="mt-5">
            <button
              onClick={() => setStage(2)}
              class="bg-primary hover:bg-white hover:text-primary hover:border text-white font-bold py-2 px-8 rounded"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : stage == 2 ? (
    <div className="flex   justify-center  min-h-screen w-full">
      <div className="mt-10 w-full md:w-96  text-center ">
        <h1 className="text-4xl">Sign Up</h1>
        <div className=" border py-7 px-4 mt-6">
          <h1 className="text-2xl">Academic</h1>

          <div className="flex gap-4 items-center mt-5 ">
            <label className="w-14">Board:</label>
            <select
              value={board}
              onChange={(e) => setBoard(e.target.value)}
              className=" border px-3 py-1 w-full rounded"
            >
              <option value=""> Choose Board/University </option>
              <option value="NEB">NEB</option>
              <option value="TU">TU</option>
            </select>
          </div>
          <div className="flex gap-4 items-center mt-5 ">
            <label className="w-14">Board:</label>
            <select
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              disabled={!board}
              className=" border px-3 py-1 w-full rounded"
            >
              <option value=""> Choose Level </option>
              {board &&
                DropDownContents[board].map((el, i) => (
                  <option key={i} value={el}>
                    {el}
                  </option>
                ))}
            </select>
          </div>
          {level && board !== 'NEB' && (
            <>
              {' '}
              <div className="flex gap-4 items-center mt-5 ">
                <label className="w-14">Board:</label>
                <select
                  className=" border px-3 py-1 w-full rounded"
                  value={course}
                  onChange={(e) => setCourse(e.target.value)}
                  disabled={!board || !level || board === 'NEB'}
                >
                  <option value=""> Choose Courses </option>
                  {level &&
                    board !== 'NEB' &&
                    DropDownContents[level].map((el, i) => (
                      <option key={i} value={el}>
                        {el}
                      </option>
                    ))}
                </select>
              </div>
            </>
          )}

          <div className="mt-5">
            <button
              onClick={() => setStage(3)}
              class="bg-primary hover:bg-white hover:text-primary hover:border text-white font-bold py-2 px-8 rounded"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex   justify-center  min-h-screen w-full">
      <div className="mt-10 w-full md:w-96  text-center ">
        <h1 className="text-4xl">Sign Up</h1>
        <div className=" border py-7 px-4 mt-6">
          <div className="flex gap-4 items-center mt-5 ">
            <label className="w-20">Password:</label>
            <input
              placeholder="Minimum 8 character"
              className=" border px-3 py-1 w-full rounded"
            />
          </div>
          <div className="flex gap-4 items-center mt-5 ">
            <label className="w-20">Confirm Password:</label>
            <input
              placeholder="Minimum 8 character"
              className=" border px-3 py-1 w-full rounded"
            />
          </div>
          <div className="mt-5">
            <button
              onClick={() => navigate('/')}
              class="bg-primary hover:bg-white hover:text-primary hover:border text-white font-bold py-2 px-8 rounded"
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
