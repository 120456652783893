import React from 'react';

const engLangDevArray = [
  '	Education and Humanity',
  'Communication',
  'Media and Society',
  'History and Culture',
  'Life and Love',
  'Health and Exercise',
  'Ecology and Development',
  'Humor and Satire',
  'Democracy and Human Rights',
  'Home Life and Family Relationship',
  'Arts and Creation',
  'Fantasy',
  'Career and Entrepreneurship',
  'Power and Politics',
  'War and Peace',
  'Critical Thinking',
  'Globalization and Diaspora',
  'Immigration and Identity',
  'Travel and Tourism',
  'Science and Technology',
];
const shortStories = [
  '	The Selfish Giant',
  'The Oval Portrait',
  'God Sees the Truth but Waits',
  'The Wish',
  'Civil Peace',
  'Two Little Soldiers',
  'An Astrologer’s Day',
];

const poems = [
  'Corona Says',
  'A Red, Red Rose',
  'All the World’s a Stage',
  'Who are you, little i?',
  'The Gift in Wartime',
];

const essays = [
  'Sharing Tradition',
  'How to Live Before You Die',
  'What I Require from Life',
  'What is Poverty?',
  'Scientific Research is a Token of Humankind’s Survival',
];

const oneActPlays = ['Trifles', 'A Sunny Morning', 'Refund'];
const English11 = () => {
  return (
    <>
      <li className="bg-primary text-white py-3  my-2">Language Development</li>
      {engLangDevArray.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Literature</li>
      <li className="bg-primary text-white py-3  my-2">Short Stories</li>
      {shortStories.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Poems</li>

      {poems.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Essays</li>

      {essays.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">One Act Plays</li>

      {oneActPlays.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
    </>
  );
};

export default English11;
