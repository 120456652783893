import React from 'react';

const lists = [
  'An Overview of Marketing',
  'Product',
  'Pricing',
  'Distribution/Place',
  'Promotion',
  'Emerging Issues in Modern Marketing',
  'Marketing Ethics and Social Responsibility',
  'Developing Salesmanship Skill',
];

const Marketing12 = () => {
  return lists.map((el, index) => (
    <li
      className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
      key={el}
    >
      {el}
    </li>
  ));
};

export default Marketing12;
