import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Grid from '../utils/Grid';

const semList = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eight',
];

const GradeList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const router = useNavigate();
  const board = searchParams.get('board');
  const level = searchParams.get('level');
  const course = searchParams.get('course');
  return (
    <div>
      <Navbar />
      <div className="min-h-screen max-w-7xl md:px-5 sm:px-10 px-5  mx-auto  pt-10 pb-20">
        <h1 className="text-4xl text-center">Choose your semester</h1>
        <div className="mt-10 flex flex-wrap justify-center gap-10">
          {semList.map((el, i) => (
            <div
              onClick={() =>
                router(
                  `/subjects?board=${board}&level=${level}&course=${course}&sem=${el}`
                )
              }
              key={i}
              className="bg-gradient-to-r from-[#005aa7] to-[rgba(0,0,0,0.8)] text-3xl lg:w-64 h-52 md:w-48 w-full flex justify-center items-center hover:scale-105 transition-all duration-200 rounded  text-center cursor-pointer text-white "
            >
              {el}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GradeList;
