import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const NotePage = () => {
  return (
    <>
      <Navbar />
      <div style={{ minHeight: '80vh' }}>
        <h1 className="text-center">Coming soon</h1>
      </div>
      <Footer />
    </>
  );
};

export default NotePage;
