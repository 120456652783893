import React from 'react';

const mechanical = [
  '1.	Volumetric analysis',
  '2.	 Ionic equilibrium',
  '3.	Chemical kinetics',
  '4.	Thermodynamics',
  '5.	Electrochemistry',
];
const heat = ['6.Transition metals', '7.Studies of heavy metals'];

const waves = [
  '8.	Haloalkanes',
  '9.	Haloarenes',
  '10.	Alcohols',
  '11.	Phenols',
  '12.	Ethers',
  '13.	Aldehydes and ketones',
  '14.	Carboxylic acid and its derivatives',
  '15.	Nitro compounds',
  '16.	Organometallic compounds',
];
const modern = [
  '17.	Chemistry in the service of mankind',
  '18.	Cement',
  '19.	Paper and pulp',
  '20.	Nuclear chemistry and application of radioactivity',
];
const Chemistry12 = () => {
  return (
    <>
      <li className="bg-primary text-white py-3  my-2">
        General and physical chemistry
      </li>
      {mechanical.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Inorganic Chemistry</li>

      {heat.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Organic Chemistry</li>

      {waves.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Applied Chemistry</li>

      {modern.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
    </>
  );
};

export default Chemistry12;
