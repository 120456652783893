import React from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  return (
    <div className="flex   justify-center  min-h-screen w-full">
      <div className="mt-10 w-full md:w-96   ">
        <h1 className="text-4xl text-center">Login</h1>
        <div className=" border py-7 px-4 mt-6">
          <div className=" mt-5 ">
            <label>Name</label>
            <input
              placeholder="Phone no default"
              className="border px-3 py-1 w-full rounded mt-2"
            />
          </div>
          <div className=" mt-5 ">
            <label>Password</label>
            <input
              type="password"
              placeholder="Your password"
              className="border px-3 py-1 w-full rounded mt-2"
            />
          </div>
          <div className="mt-5 text-center">
            <button
              onClick={() => navigate('/')}
              class="bg-primary hover:bg-white hover:text-primary hover:border text-white font-bold py-2 px-8 rounded"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
