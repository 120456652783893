import React from 'react';

const DropDown = ({ children, value, onChange, disabled = false }) => {
  return (
    <select
      disabled={disabled}
      onChange={onChange}
      value={value}
      className="cursor-pointer bg-white text-primary border border-primary px-3 rounded focus:outline-none   md:w-44  py-2"
    >
      {children}
    </select>
  );
};

export default DropDown;
