import React, { useState, useEffect } from 'react';
import Logo from '../assets/images/padandaslogo.png';
import DropDown from '../utils/DropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import '../styles/navbar.css';
import { Link, useNavigate } from 'react-router-dom';

const DropDownContents = {
  NEB: ['SEE', 11, 12],
  TU: ['IOST', 'IOE', 'IOM'],
  IOST: ['CSIT', 'BIT'],
  IOE: ['BCT', 'BCE', 'BEX'],
};

const MobileView = () => {
  const [click, setClick] = useState(false);
  const [board, setBoard] = useState('');
  const router = useNavigate();
  const [course, setCourse] = useState('');
  const [level, setLevel] = useState('');

  useEffect(() => {
    if (level && board === 'NEB') {
      router(`/subjects?board=NEB&level=${level}`);
      setLevel('');
      setCourse('');
      setBoard('');
    }
    if (level && board !== 'NEB' && course) {
      router(`/grade?board=${board}&level=${level}&course=${course}`);
      setLevel('');
      setCourse('');
      setBoard('');
    }
  }, [board, level, router, course]);

  return (
    <div>
      <nav className="   text-white   bg-primary ">
        <div className="flex justify-between items-center ">
          <div className="flex items-center ">
            <div className="w-28  bg-white">
              <img src={Logo} alt="Logo" />
            </div>
            <div
              onClick={() => setClick(!click)}
              className="ml-10 cursor-pointer ham-menu"
            >
              {!click ? (
                <FontAwesomeIcon className="text-3xl" icon={faBars} />
              ) : (
                <FontAwesomeIcon className="text-3xl" icon={faXmark} />
              )}
            </div>
          </div>

          <ul className="flex mr-1">
            <li className="mr-3">Sign In</li>
            <li onClick={() => router('/signup')}>Sign Up</li>
          </ul>
        </div>
      </nav>
      {click && (
        <ul key={click} className={'nav-options'}>
          <li className="hover:text-lightgray">
            <Link to="/note-page">Notes</Link>
          </li>
          <li className="hover:text-lightgray">
            <Link to="/colleges">Colleges</Link>
          </li>
          <li className="hover:text-lightgray">
            <Link to="/consultancy">Consultancy</Link>
          </li>
          <li className="hover:text-lightgray">
            <Link to="/news">News</Link>
          </li>
          <li className="hover:text-lightgray">
            <Link to="/blogs">Blogs</Link>
          </li>
        </ul>
      )}
      <div className="flex justify-center mt-5 ">
        <input
          placeholder="Search Here...."
          className=" border px-2 py-1 "
          type="text"
        />
      </div>
      <div className="flex flex-wrap mt-3 gap-3 px-2">
        <DropDown value={board} onChange={(e) => setBoard(e.target.value)}>
          <option value=""> Choose Board/Uni </option>
          <option value="NEB">NEB</option>
          <option value="TU">TU</option>
        </DropDown>

        <DropDown
          value={level}
          onChange={(e) => setLevel(e.target.value)}
          disabled={!board}
        >
          <option value=""> Choose Level </option>
          {board &&
            DropDownContents[board].map((el, i) => (
              <option key={i} value={el}>
                {el}
              </option>
            ))}
        </DropDown>

        <DropDown
          value={course}
          onChange={(e) => setCourse(e.target.value)}
          disabled={!board || !level || board === 'NEB'}
        >
          <option value=""> Choose Courses </option>
          {level &&
            board !== 'NEB' &&
            DropDownContents[level].map((el, i) => (
              <option key={i} value={el}>
                {el}
              </option>
            ))}
        </DropDown>
      </div>
    </div>
  );
};

const Navbar = () => {
  const [board, setBoard] = useState('');
  const router = useNavigate();
  const [course, setCourse] = useState('');
  const [level, setLevel] = useState('');
  const [more, setMore] = useState('');

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    if (more) {
      router(`/${more}`);
    }
  }, [more, router]);
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (level && board === 'NEB') {
      router(`/subjects?board=NEB&level=${level}`);
      setLevel('');
      setCourse('');
      setBoard('');
    }
    if (level && board !== 'NEB' && course) {
      router(`/grade?board=${board}&level=${level}&course=${course}`);
      setLevel('');
      setCourse('');
      setBoard('');
    }
  }, [board, level, router, course]);

  return (
    <>
      {width > 1280 ? (
        <nav className="flex bg-primary justify-between items-center">
          <div className="logo-container cursor-pointer">
            <img onClick={() => router('/')} src={Logo} alt="Logo" />
          </div>
          <div className="flex  nav-sections    flex-col">
            <ul className="py-1 ml-1 flex text-white flex-row justify-around items-center border-b-4 border-white pb-3">
              <li className="hover:text-lightgray">
                <Link to="/note-page">Notes</Link>
              </li>
              <li className="hover:text-lightgray">
                <Link to="/colleges">Colleges</Link>
              </li>
              <li className="hover:text-lightgray">
                <Link to="/consultancy">Consultancy</Link>
              </li>
              <li className="hover:text-lightgray">
                <Link to="/news">News</Link>
              </li>
              <li className="hover:text-lightgray">
                <Link to="/blogs">Blogs</Link>
              </li>
              <li>
                <select
                  value={more}
                  onChange={(e) => setMore(e.target.value)}
                  className="bg-transparent pr-1 cursor-pointer  text-center"
                >
                  <option className="text-black" value="">
                    More
                  </option>
                  <option
                    className="text-black"
                    onClick={() => router('/career')}
                    value="career"
                  >
                    Career
                  </option>
                  <option
                    onClick={() => router('/counselling')}
                    className="text-black"
                    value="counselling"
                  >
                    Counselling
                  </option>
                </select>
              </li>
            </ul>
            <ul className="flex mt-4 ml-2 pb-1 justify-between">
              <li>
                <DropDown
                  value={board}
                  onChange={(e) => setBoard(e.target.value)}
                >
                  <option value=""> Choose Board/University </option>
                  <option value="NEB">NEB</option>
                  <option value="TU">TU</option>
                </DropDown>
              </li>
              <li>
                <DropDown
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                  disabled={!board}
                >
                  <option value=""> Choose Level </option>
                  {board &&
                    DropDownContents[board].map((el, i) => (
                      <option key={i} value={el}>
                        {el}
                      </option>
                    ))}
                </DropDown>
              </li>
              <li>
                <DropDown
                  value={course}
                  onChange={(e) => setCourse(e.target.value)}
                  disabled={!board || !level || board === 'NEB'}
                >
                  <option value=""> Choose Courses </option>
                  {level &&
                    board !== 'NEB' &&
                    DropDownContents[level].map((el, i) => (
                      <option key={i} value={el}>
                        {el}
                      </option>
                    ))}
                </DropDown>
              </li>
            </ul>
          </div>
          <div className="flex ml-2 mb-5">
            <input
              className="rounded px-3 py-2 w-72 "
              placeholder="Search Notes, Colleges, Counsils"
            />
          </div>
          <ul className="flex flex-col gap-3 mr-3  mb-1">
            <li>
              <button
                onClick={() => router('/login')}
                className="bg-white text-primary py-2 px-5 rounded hover:bg-primary hover:text-white hover:border font-bold"
              >
                Sign In
              </button>
            </li>
            <li>
              <button
                onClick={() => router('/signup')}
                className="bg-white text-primary py-2 px-4 rounded hover:bg-primary hover:text-white hover:border font-bold"
              >
                Sign Up
              </button>
            </li>
          </ul>
        </nav>
      ) : (
        <MobileView />
      )}
    </>
  );
};

export default Navbar;
