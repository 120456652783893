import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const seeList = [
  'Compulsory English',
  'Compulsory Mathematics',
  'Compulsory Nepali',
  'Compulsory Science',
  'Computer Science',
  'Health Population Education',
  'Account',
  'Optional Math',
  'Optional Literature',
  'Social Studies',
];

const elevenList = [
  'Nepali',
  'English',
  'Social Studies',
  'Physics',
  'Accounting',
  'Biology',
  'Business Study',
  'Computer Science',
  'Chemistry',
  'Economics',
  'Mathematics',
  'Marketing',
  'Business Math',
  'Hotel Management',
  'Finance',
];
const twelveList = [
  'Nepali',
  'English',
  'Social Studies',
  'Physics',
  'Accounting',
  'Biology',
  'Business Study',
  'Computer Science',
  'Chemistry',
  'Economics',
  'Mathematics',
  'Marketing',
  'Business Math',
  'Hotel Management',
  'Finance',
];

const SubjectPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const nebObj = {
    SEE: seeList,
    11: elevenList,
    12: twelveList,
  };

  const subObj = {
    IOST: {
      CSIT: {
        First: [
          'Digital Logic',
          'Math I',
          'C Programming',
          'Physics',
          'Introduction to IT',
        ],
      },
    },
  };
  const router = useNavigate();
  const board = searchParams.get('board');
  const level = searchParams.get('level');
  const course = searchParams.get('course');
  const sem = searchParams.get('sem');

  const subjectList =
    board === 'NEB' ? nebObj[level] : subObj[level][course][sem];

  const routeHandler = (sub) => {
    let route = `/notes?board=${board}&level=${level}`;
    if (course) {
      route = route + `&course=${course}`;
    }

    if (sem) {
      route = route + `&sem=${sem}`;
    }
    route = route + `&subject=${sub}`;
    router(route);
  };

  return (
    <div>
      <Navbar />
      <div className="min-h-screen max-w-7xl md:px-5 sm:px-10 px-5  mx-auto  pt-10 pb-20">
        <h1>
          <span>{board}</span>/<span>{level}</span>
          <span>
            {course ? (
              <span
                className="cursor-pointer"
                onClick={() =>
                  router(
                    `/grade?board=${board}&level=${level}&course=${course}`
                  )
                }
              >{`/${course}`}</span>
            ) : (
              ''
            )}
            <span>{sem ? `/${sem} sem` : ''}</span>
          </span>
        </h1>
        <div className="min-h-screen flex flex-col items-center gap-5 pt-10 pb-20">
          <h1 className="text-4xl">Choose grade {level} subjects</h1>
          <div className="mt-10 flex flex-wrap justify-center gap-10">
            {subjectList.length > 0 ? (
              subjectList.map((el, i) => (
                <div
                  onClick={() => routeHandler(el)}
                  key={i}
                  className="bg-gradient-to-r from-[#005aa7] to-[rgba(0,0,0,0.8)] text-3xl lg:w-64 h-52 md:w-48 w-full flex justify-center items-center hover:scale-105 transition-all duration-200 rounded  text-center cursor-pointer text-white "
                >
                  {el}
                </div>
              ))
            ) : (
              <p>No subjetct found</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SubjectPage;
