import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import BusinessMath11 from '../components/subjectList/BusinessMath11';
import BusinessMath12 from '../components/subjectList/BusinessMath12';
import Chemistry11 from '../components/subjectList/Chemistry11';
import Chemistry12 from '../components/subjectList/Chemistry12';
import English11 from '../components/subjectList/CompulsoryEnglish11';
import English12 from '../components/subjectList/CompulsoryEnglish12';
import Computer11 from '../components/subjectList/Computer11';
import Computer12 from '../components/subjectList/Computer12';
import Finance11 from '../components/subjectList/Finance11';
import Finance12 from '../components/subjectList/Finance12';
import Marketing11 from '../components/subjectList/Marketing11';
import Marketing12 from '../components/subjectList/Marketing12';
import Nepali11 from '../components/subjectList/Nepali11';
import Nepali12 from '../components/subjectList/Nepali12';
import Physics from '../components/subjectList/Physics11';
import Physics12 from '../components/subjectList/Physics12';

const lists = {
  NEB: {
    11: {
      English: <English11 />,
      Nepali: <Nepali11 />,
      Marketing: <Marketing11 />,
      Finance: <Finance11 />,
      Physics: <Physics />,
      Chemistry: <Chemistry11 />,
      'Business Math': <BusinessMath11 />,
      'Computer Science': <Computer11 />,
    },
    12: {
      English: <English12 />,
      Nepali: <Nepali12 />,
      Marketing: <Marketing12 />,
      Finance: <Finance12 />,
      Physics: <Physics12 />,
      Chemistry: <Chemistry12 />,
      'Business Math': <BusinessMath12 />,
      'Computer Science': <Computer12 />,
    },
  },
};

const NotesPage = () => {
  const params = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const mcq = searchParams.get('mcq');
  const syllabus = searchParams.get('syllabus');
  const board = searchParams.get('board');
  const level = searchParams.get('level');
  const subject = searchParams.get('subject');
  const course = searchParams.get('course');
  const sem = searchParams.get('sem');
  const question_collection = searchParams.get('question_collection');
  const mainRoute = `${params.pathname}${params.search}`;

  const subjectNote = lists[board][level][subject];

  const routeHandler = (val) => {
    let route = mainRoute;

    if (val === 'notes') {
      route = route.replace('&syllabus=true', '');
      route = route.replace('&question_collection=true', '');
      route = route.replace('&mcq=true', '');
      return navigate(route);
    }
    if (val === 'mcq') {
      route = route.replace('&syllabus=true', '');
      route = route.replace('&question_collection=true', '');
    }
    if (val === 'syllabus') {
      route = route.replace('&mcq=true', '');
      route = route.replace('&question_collection=true', '');
    }
    if (val === 'question_collection') {
      route = route.replace('&mcq=true', '');
      route = route.replace('&syllabus=true', '');
    }
    route = route + `&${val}=true`;
    navigate(route);
  };

  return (
    <div>
      <Navbar />
      <div className="pb-20 flex ">
        <div
          style={{ height: '800px' }}
          className="mt-4 h-2/4 text-center overflow-y-scroll w-1/5"
        >
          <h1 className="text-2xl text-center border-b-2">Chapters</h1>
          <ul className="mt-4 ">{subjectNote}</ul>
        </div>
        <div className="w-full p-5">
          <div className="flex justify-center">
            <ul className="flex gap-20 text-md text-gray uppercase">
              <li
                onClick={() => routeHandler('notes')}
                className={`${
                  !mcq && !question_collection && !syllabus && `active`
                } pb-2 cursor-pointer`}
              >
                Notes
              </li>
              <li
                onClick={() => routeHandler('mcq')}
                className={`${mcq && 'active'} pb-2 cursor-pointer`}
              >
                MCQ
              </li>
              <li
                onClick={() => routeHandler('syllabus')}
                className={`${syllabus && 'active'} cursor-pointer`}
              >
                Syllabus{' '}
              </li>
              <li
                onClick={() => routeHandler('question_collection')}
                className={`${
                  question_collection && 'active'
                } pb-2 cursor-pointer`}
              >
                Question Collection
              </li>
            </ul>
          </div>
          <iframe
            className="mt-10"
            title="notes"
            src="https://firebasestorage.googleapis.com/v0/b/padandaas-3eb1c.appspot.com/o/Class%2011%2FAccountancy%20(1031)%2FQC%2FAccountancy.pdf?alt=media&token=0e9c2b9a-19bc-4eda-a860-e7fcb6ce4075"
            width="100%"
            height="700px"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotesPage;
