import React from 'react';

const lists = [
  'आमाको सपना (कविता)',
  'विरहिणी दमयन्ती (कथा)',
  'घनघस्याको उकालो काट्ता (नियात्रा)',
  'व्यावसायिक पत्र (व्यावहारिक लेखन)',
  'एक चिहान (उपन्यास)',
  'स्टिफन विलियम हकिङ (जीवनी)',
  'हामीलाई बोलाउँछन् हिमचुली (कविता)',
  'मातृत्व (कथा)',
  'गोर्खे (कथा)',
  'नेपाली पहिचान (संवाद)',
  'सहकारी (निबन्ध)',
  'जीवन मार्ग (रिपोर्ताजमुलक रचना)',
];

const Nepali12 = () => {
  return lists.map((el, index) => (
    <li
      className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
      key={el}
    >
      {el}
    </li>
  ));
};

export default Nepali12;
