import React from 'react';

const mechanical = [
  'Rotational dynamics ',
  'Periodic motion ',
  'Fluid statics ',
];
const heat = ['First Law of Thermodynamics ', 'Second Law of Thermodynamics '];

const waves = [
  'Wave motion ',
  'Mechanical waves ',
  'Wave in pipes and strings ',
  'Acoustic phenomena ',
  'Nature and propagation of light ',
  'Interference ',
  'Diffraction ',
  'Polarization',
];
const electricity = [
  'Electrons ',
  'Photons ',
  'Semiconductor devices ',
  'Quantization of energy ',
  'Radioactivity and nuclear reaction ',
  'Recent trends in physics',
];

const modern = ['Nuclear physics ', 'Solids ', 'Recent Trends in physics '];
const Physics12 = () => {
  return (
    <>
      <li className="bg-primary text-white py-3  my-2">Mechanics</li>
      {mechanical.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">
        Heat and Thermodynamics
      </li>

      {heat.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Waves and Optics</li>

      {waves.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">
        Electricity and Magnetism
      </li>

      {electricity.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Modern Physics</li>

      {modern.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
    </>
  );
};

export default Physics12;
