import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faFacebook,
  faWhatsapp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-primary p-1 pb-3 text-white">
      <div className="max-w-screen-xl flex flex-wrap mx-auto justify-between">
        <div style={{ minWidth: '200px' }} className="mt-2 ">
          <h1 className="text-2xl">About</h1>
          <ul className="mt-3">
            <li>
              {' '}
              <Link to="/">Home</Link>
            </li>
            <li>
              {' '}
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              {' '}
              <Link to="/privacy">Privacy</Link>
            </li>
            <li>
              {' '}
              <Link to="/terms">Terms and Conditions</Link>
            </li>
          </ul>
        </div>
        <div style={{ minWidth: '200px' }} className="mt-2">
          <h1 className="text-2xl">Services</h1>
          <ul className="mt-3">
            <li>
              <Link to="/note-page">Notes</Link>
            </li>
            <li>
              <Link to="/note-page">MCQS</Link>
            </li>
            <li>
              <Link to="/note-page">Question Collection</Link>
            </li>
            <li>
              <Link to="/note-page">Syllabus</Link>
            </li>
          </ul>
        </div>
        <div style={{ minWidth: '200px' }} className="mt-2">
          <h1 className="text-2xl">Follow Us</h1>
          <ul className="mt-3 ml-8">
            <li className="text-xl">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>

            <li className="text-xl">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li className="text-xl">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
            </li>
            <li className="text-xl">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
          </ul>
        </div>
        <div style={{ minWidth: '200px' }} className="mt-2">
          <h1 className="text-2xl">Contact</h1>
          <ul className="mt-3">
            <li>Padandaas Nepal</li>
            <li>Balkhu, Kathmandu</li>
            <li>9845113297</li>
            <li>padandaas@gmail.com</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
