import React from 'react';

const mechanical = [
  '1.	Foundation and Fundamentals',
  '2.	Stoichiometry',
  '3.	Atomic structure',
  '4.	Classification of elements and periodic table',
  '5.	Chemical bonding and shapes of molecules',
  '6.	Oxidation and reduction	 ',
  '7.	States of matter	 ',
  '8.	Chemical equilibrium',
];
const heat = [
  '9.	Chemistry of Non-metal',
  '10.	Chemistry of metals',
  '11.	Bio-inorganic chemistry',
];

const waves = [
  '12.	Basic concept of organic chemistry',
  '13.	Fundamental principles',
  '14.	Hydrocarbons',
  '15.	Aromatic hydrocarbons',
];

const modern = [
  '16.	Fundamentals of applied chemistry',
  '17.	Modern chemical manufactures',
];
const Chemistry11 = () => {
  return (
    <>
      <li className="bg-primary text-white py-3  my-2">
        General and physical chemistry
      </li>
      {mechanical.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Inorganic Chemistry</li>

      {heat.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Organic Chemistry</li>

      {waves.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Applied Chemistry</li>

      {modern.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
    </>
  );
};

export default Chemistry11;
