import React, { useState } from 'react';

const Alzebra = [
  '1.1 Linear Functions, Equation and Inequalities',
  '1.2 Applications',
  '1.3 System of linear equations',
  '1.4 Applications',
  '1.5 Quadratic Functions and Equations',
  '1.6 Applications',
  '1.7 Exponential and Logarithmic functions',
  '1.8 Comp. Work',
];
const calculus = [
  '2.1 Limit and Continuity',
  '2.2 Differentiation',
  '2.2 Differentiation',
  '2.4 Indefinite Integration',
];

const Financial = ['3.1 Financial Mathematics', '3.2 Comp. Work'];
const linear = ['4.1 Linear Programming Problem', '4.2 Comp. Work'];
const statistics = [
  '5.1 Measures of central Tendency',
  '5.2 Measures of Dispersion',
  '5.3 Comp. Work',
];

const probability = [
  '6.1 Permutation and Combination',
  '6.2 Probability',
  '6.3 Comp. Work',
];

const BusinessMath11 = () => {
  const [alzebraShow, setAlzebraShow] = useState(false);
  const [calculusShow, setcalculsShow] = useState(false);
  const [mathematicalShow, setMathematicalShow] = useState(false);
  const [linearShow, setLinearShow] = useState(false);
  const [statisticShow, setStatisticShow] = useState(false);
  const [probabilityShow, setprobabilityShow] = useState(false);
  return (
    <>
      <li
        onClick={() => setAlzebraShow(!alzebraShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        1. Algebra{' '}
        {alzebraShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {alzebraShow &&
        Alzebra.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setcalculsShow(!calculusShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        2. Calculus
        {calculusShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {calculusShow &&
        calculus.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setMathematicalShow(!mathematicalShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        3. Financial Mathematics
        {mathematicalShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {mathematicalShow &&
        Financial.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setLinearShow(!linearShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        4. Linear Programming Problem
        {linearShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {linearShow &&
        linear.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setStatisticShow(!statisticShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        5. Statistics
        {statisticShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {statisticShow &&
        statistics.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setprobabilityShow(!probabilityShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        6. Probability
        {probabilityShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {probabilityShow &&
        probability.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
    </>
  );
};

export default BusinessMath11;
