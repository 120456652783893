import React from 'react';
import BlogSection from './BlogSection';
import ContentSection from './ContentSection';
import NewsSection from './NewsSection';

const MainSection = () => {
  return (
    <div className="mt-4 flex items-stretch h-full">
      <NewsSection />
      <ContentSection />
      <BlogSection />
    </div>
  );
};

export default MainSection;
