import React, { useState } from 'react';

const Alzebra = [
  '1.1 Introduction',
  '1.2 Keys ',
  '1.3 Advantages',
  '1.4 DDL and DML',
  '1.5 Database ',
  '1.6 Normalization: 1NF, 2NF, 3NF ',
  '1.7 Centralized Vs. Distributed Database ',
  '1.8 Database Security',
];
const calculus = [
  '2.1 Communication System:  ',
  '2.2 Communication mode ',
  '2.3 LAN and WAN ',
  '2.4 Transmission Medium ',
  '2.5 Transmission impairments ',
  '2.6 Networks Architecture',
  '2.7 Some Basic Terms and Tool',
  '2.8 Network Tool ',
  '2.9 Network Connecting Devices ',
  '2.10 Network Topologies ',
  '2.11 OSI Reference Model ',
  '2.12 Internet Protocol Addressing',
];

const Financial = [
  '3.1 Introduction ',
  '3.2 Server side and Client-Side Scripting ',
  '3.3 JavaScript',
  '3.4 jQuery',
  '3.5 PHP',
  '3.6 Database Connectivity ',
];
const linear = [
  '4.1 Review of C programming concept ',
  '4.2 Functions ',
  '4.3 Structures and Unions ',
  '4.4 Pointers ',
  '4.5 Working with Files ',
];
const statistics = [
  '5.1 Programming paradigms ',
  '5.2 Features of OOP ',
  '5.3 Advantages of OOP ',
  '5.4 Application of OOP',
];

const probability = [
  '6.1 Software Project Concept',
  '6.2 Software development process ',
  '6.3 Concept SDLC life cycle ',
  '6.4 System Analyst Vs Software Engineer ',
  '6.5 Requirement Collection Methods ',
  '6.6 Concept of system design ',
  '6.7 Software and quality ',
  '6.8 Software development models',
];

const multimedia = [
  '7.1 Artificial Intelligence (AI) and Robotics ',
  '7.2 Cloud Computing ',
  '7.3 Big Data ',
  '7.4 Virtual Reality ',
  '7.5 E-com, E-medicine, E-gov. ',
  '7.6 Concept of Mobile Computing ',
  '7.7 Concept of Internet of things (IoT)',
];

const Computer12 = () => {
  const [alzebraShow, setAlzebraShow] = useState(false);
  const [calculusShow, setcalculsShow] = useState(false);
  const [mathematicalShow, setMathematicalShow] = useState(false);
  const [linearShow, setLinearShow] = useState(false);
  const [statisticShow, setStatisticShow] = useState(false);
  const [probabilityShow, setprobabilityShow] = useState(false);
  const [multimediaShow, setMultiMediaShow] = useState(false);

  return (
    <>
      <li
        onClick={() => setAlzebraShow(!alzebraShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        1. Database Management System{' '}
        {alzebraShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {alzebraShow &&
        Alzebra.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setcalculsShow(!calculusShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        2. Data Communication and Networking
        {calculusShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {calculusShow &&
        calculus.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setMathematicalShow(!mathematicalShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        3. Web Technology II
        {mathematicalShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {mathematicalShow &&
        Financial.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setLinearShow(!linearShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        4. Programming in C
        {linearShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {linearShow &&
        linear.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setStatisticShow(!statisticShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        5. Object Oriented Programming (OOP)
        {statisticShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {statisticShow &&
        statistics.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setprobabilityShow(!probabilityShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        6. Software Process Model (SPM)
        {probabilityShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {probabilityShow &&
        probability.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setMultiMediaShow(!multimediaShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        7. Recent Trends in Technology
        {multimediaShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {multimediaShow &&
        multimedia.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
    </>
  );
};

export default Computer12;
