import React from 'react';

const BlogSection = () => {
  return (
    <div className=" lg:block md:block hidden py-5 w-72 bg-gray ">
      <div className="flex justify-around items-center">
        <button className="bg-white px-3 py-1 rounded text-primary hover:bg-primary hover:text-white">
          Blogs
        </button>
        <select className="py-1 px-3 cursor-pointer">
          <option value="">All</option>
          <option value="">Few</option>
        </select>
      </div>
      <div className="flex mt-3 px-2 flex-col gap-1 items-center">
        {/* <div className="bg-primary text-white rounded ">
          Class 12 exam starts in Ashwin 1 and ends in Ashwin 29
        </div>
        <div className="bg-primary text-white rounded ">
          Class 12 exam starts in Ashwin 1 and ends in Ashwin 29
        </div>
        <div className="bg-primary text-white rounded ">
          Class 12 exam starts in Ashwin 1 and ends in Ashwin 29
        </div>
        <div className="bg-primary text-white rounded ">
          Class 12 exam starts in Ashwin 1 and ends in Ashwin 29
        </div>
        <div className="bg-primary text-white rounded ">
          Class 12 exam starts in Ashwin 1 and ends in Ashwin 29
        </div>
        <div className="bg-primary text-white rounded ">
          Class 12 exam starts in Ashwin 1 and ends in Ashwin 29
        </div>
        <div className="bg-primary text-white rounded ">
          Class 12 exam starts in Ashwin 1 and ends in Ashwin 29
        </div>
        <div className="bg-primary text-white rounded ">
          Class 12 exam starts in Ashwin 1 and ends in Ashwin 29
        </div> */}
      </div>
    </div>
  );
};

export default BlogSection;
