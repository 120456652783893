import React from 'react';

const lists = [
  'Introduction to Business Finance',
  'Business Entity and Financial Environment',
  'Financial Assets',
  'Financial Markets',
  'Commercial Banks',
  'Micro Finance and Cooperatives',
  'Risk and Insurance',
  'Life and non-life Insurance',
  'Mutual Fund, Pension Fund and other Financial Service Companies',
  'Time value of money',
];

const Finance11 = () => {
  return lists.map((el, index) => (
    <li
      className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
      key={el}
    >
      {el}
    </li>
  ));
};

export default Finance11;
