import React from 'react';

const mechanical = [
  'Physical Quantities ',
  'Vectors',
  'Kinematics ',
  'Dynamics ',
  'Work, energy and power ',
  ' Circular Motion ',
  'Gravitation ',
  'Elasticity ',
];
const heat = [
  'Heat and Temperature ',
  'Thermal Expansion ',
  'Quantity of Heat',
  'Rate of heat flow ',
  'Ideal gas ',
];

const waves = [
  'Reflection at curved mirror ',
  'Refraction at plane surfaces ',
  'Refraction through prisms ',
  'Lenses ',
  'Dispersion ',
];
const electricity = [
  'Electric Charges',
  'Electric field ',
  'Potential, potential difference and potential energy ',
  'Capacitor',
  'DC Circuits ',
];

const modern = ['Nuclear physics ', 'Solids ', 'Recent Trends in physics '];
const Physics = () => {
  return (
    <>
      <li className="bg-primary text-white py-3  my-2">Mechanics</li>
      {mechanical.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">
        Heat and Thermodynamics
      </li>

      {heat.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Waves and Optics</li>

      {waves.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">
        Electricity and Magnetism
      </li>

      {electricity.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Modern Physics</li>

      {modern.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
    </>
  );
};

export default Physics;
