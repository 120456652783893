import React from 'react';

const lists = [
  'Introduction to Marketing',
  'Evolution of Marketing Concept',
  'Marketing Mix and Its Components',
  'Components of Marketing',
  'Marketing Functions',
  'Marketing Environment',
  'Buyer Behavior and Buying Decision',
  'Marketing Information System',
];

const Marketing11 = () => {
  return lists.map((el, index) => (
    <li
      className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
      key={el}
    >
      {el}
    </li>
  ));
};

export default Marketing11;
