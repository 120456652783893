import React, { useState } from 'react';

const Alzebra = ['1.1 Introduction', '1.2 Computer system and I/O devices '];
const calculus = [
  '2.1 Number System and conversion ',
  '2.2 Logic Function and Boolean Algebra ',
];

const Financial = [
  '3.1 Software ',
  '3.2 Operating System ',
  '3.3 Windows Operating System ',
  '3.4 Open sources and Mobile Operating System ',
];
const linear = [
  '4.1 Introduction to Office Package',
  '4.2 Introduction to domain-specific tools',
];
const statistics = ['5.1 Programming Concept', '5.2 C Programming Languages '];

const probability = [
  '6.1 Web development introduction ',
  '6.2 Web browsers and search Engines ',
  '6.3 Overview of various internet & web technologies ',
  '6.4 Content Management System (CMS) ',
  '6.4. HTML: The Language of the Web ',
  '6.5 Cascading Style Sheets ',
];

const multimedia = [
  '7.1 Introduction to Multimedia ',
  '7.2 Components of Multimedia ',
  '7.3 Application of Multimedia',
];

const info = [
  '8.1 Digital society and computer ethics ',
  '8.2 Concept of Information security ',
  '8.3 Concept of Cybercrime ',
  '8.4 Malicious software and Spam ',
  '8.5 Protection from cybercrime',
  '8.6 Intellectual Property Right ',
  '8.7 Concept of Digital Signature ',
  '8.8 Concept of Cyber Law in Nepal ',
  '8.9 ICT Policy in Nepal',
];

const Computer11 = () => {
  const [alzebraShow, setAlzebraShow] = useState(false);
  const [calculusShow, setcalculsShow] = useState(false);
  const [mathematicalShow, setMathematicalShow] = useState(false);
  const [linearShow, setLinearShow] = useState(false);
  const [statisticShow, setStatisticShow] = useState(false);
  const [probabilityShow, setprobabilityShow] = useState(false);
  const [multimediaShow, setMultiMediaShow] = useState(false);
  const [infoShow, setInfoShow] = useState(false);
  return (
    <>
      <li
        onClick={() => setAlzebraShow(!alzebraShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        1. Computer System{' '}
        {alzebraShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {alzebraShow &&
        Alzebra.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setcalculsShow(!calculusShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        2. Number system and conservation Boolean Logic
        {calculusShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {calculusShow &&
        calculus.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setMathematicalShow(!mathematicalShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        3. Computer Software and Operating System
        {mathematicalShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {mathematicalShow &&
        Financial.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setLinearShow(!linearShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        4. Application Package
        {linearShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {linearShow &&
        linear.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setStatisticShow(!statisticShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        5. Programming Concepts & Logic
        {statisticShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {statisticShow &&
        statistics.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setprobabilityShow(!probabilityShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        6. Web Technology I
        {probabilityShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {probabilityShow &&
        probability.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setMultiMediaShow(!multimediaShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        7. Multimedia
        {multimediaShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {multimediaShow &&
        multimedia.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setInfoShow(!infoShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        8. Information Security and Cyber Law
        {infoShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {infoShow &&
        info.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
    </>
  );
};

export default Computer11;
