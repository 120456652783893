import React, { useState } from 'react';

const Alzebra = ['1.1 Matrices and Determinants', '1.2 Comp. Work'];
const calculus = [
  '2.1 Applications of Derivatives',
  '2.2 Definite Integral and its Applications',
  '2.3 Differential equations and Applications',
];

const Financial = ['3.1 Financial Mathematics', '3.2 Comp. Work'];
const linear = ['4.1 Linear Programming Problem', '4.2 Comp. Work'];
const statistics = [
  '5.1 Skewness',
  '5.2 Correlation',
  '5.3 Regression',
  '5.4 Comp. Work',
];

const probability = [
  '6.1 Probability',
  '6.2 Random Variables',
  '6.3 Binomial Distribution',
  '6.4 Comp. Work',
];

const BusinessMath12 = () => {
  const [alzebraShow, setAlzebraShow] = useState(false);
  const [calculusShow, setcalculsShow] = useState(false);
  const [mathematicalShow, setMathematicalShow] = useState(false);
  const [linearShow, setLinearShow] = useState(false);
  const [statisticShow, setStatisticShow] = useState(false);
  const [probabilityShow, setprobabilityShow] = useState(false);
  return (
    <>
      <li
        onClick={() => setAlzebraShow(!alzebraShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        1. Algebra{' '}
        {alzebraShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {alzebraShow &&
        Alzebra.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setcalculsShow(!calculusShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        2. Calculus
        {calculusShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {calculusShow &&
        calculus.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setMathematicalShow(!mathematicalShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        3. Financial Mathematics
        {mathematicalShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {mathematicalShow &&
        Financial.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setLinearShow(!linearShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        4. Linear Programming Problem
        {linearShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {linearShow &&
        linear.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setStatisticShow(!statisticShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        5. Statistics
        {statisticShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {statisticShow &&
        statistics.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
      <li
        onClick={() => setprobabilityShow(!probabilityShow)}
        className="bg-primary text-white py-3 cursor-pointer ease-in-out duration-300  my-2"
      >
        6. Probability
        {probabilityShow ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}
      </li>
      {probabilityShow &&
        probability.map((el, index) => (
          <li className="bg-lightgray  py-3 hover:bg-gray hover:text-white cursor-pointer my-2">
            {el}
          </li>
        ))}
    </>
  );
};

export default BusinessMath12;
