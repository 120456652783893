import './App.css';
import HomePage from './pages/HomePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SubjectPage from './pages/SubjectList';
import GradeList from './pages/GradeList';
import NotesPage from './pages/NotesPage';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import NotePage from './pages/NotePage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/subjects" element={<SubjectPage />} />
          <Route path="/grade" element={<GradeList />} />
          <Route path="/notes" element={<NotesPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/note-page" element={<NotePage />} />
          <Route path="/colleges" element={<NotePage />} />
          <Route path="/consultancy" element={<NotePage />} />
          <Route path="/news" element={<NotePage />} />
          <Route path="/blogs" element={<NotePage />} />
          <Route path="/about-us" element={<NotePage />} />
          <Route path="/privacy" element={<NotePage />} />
          <Route path="/about-us" element={<NotePage />} />
          <Route path="/about-us" element={<NotePage />} />
          <Route path="/terms" element={<NotePage />} />
          <Route path="/career" element={<NotePage />} />
          <Route path="/counselling" element={<NotePage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
