import React from 'react';

const engLangDevArray = [
  'Critical Thinking',
  'Family',
  'Sports',
  'Technology',
  'Education',
  'Money and Economy',
  'Humor',
  'Human Culture',
  'Ecology and Environment',
  'Career Opportunities',
  'Hobbies',
  'Animal World',
  'History',
  'Human Rights',
  'Leisure and Entertainment',
  'Fantasy',
  'War and Peace',
  'Music and Creation',
  'Migration and Diaspora',
  'Power and Politics',
];
const shortStories = [
  'Neighbors',
  'A Respectable Woman',
  'A Devoted Son',
  'The Treasure in the Forest',
  'My Old Home',
  'The Half-closed Eyes of the Buddha and the Slowly Sinking Sun',
  'A Very Old Man with Enormous Wings',
];
const poems = [
  'A Day',
  'Every Morning I Wake',
  'I Was My Own Route',
  'The Awakening Age',
  'Soft Storm',
];
const essays = [
  'On Libraries',
  'Marriage as a Social Institution',
  'Knowledge and Wisdom',
  'Humility',
  'Human Rights and the Age of Inequality',
];
const oneActPlays = ['A Matter of Husbands', 'Facing Death', 'The Bull'];
const English12 = () => {
  return (
    <>
      <li className="bg-primary text-white py-3  my-2">Language Development</li>
      {engLangDevArray.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Literature</li>
      <li className="bg-primary text-white py-3  my-2">Short Stories</li>
      {shortStories.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Poems</li>

      {poems.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">Essays</li>

      {essays.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
      <li className="bg-primary text-white py-3  my-2">One Act Plays</li>

      {oneActPlays.map((el, index) => (
        <li
          className="bg-lightgray py-3 hover:bg-gray hover:text-white cursor-pointer my-2"
          key={el}
        >
          {el}
        </li>
      ))}
    </>
  );
};

export default English12;
